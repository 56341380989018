<template>
    <!-- eslint-disable max-len -->
    <BaseDialog @close="$emit('close')" class="DisconnectDialog">
        <header class="DisconnectDialog_Machine relative">
            <img src="@/assets/images/machine-types/vending.png" class="w-32 mx-auto" />

            <div class="DisconnectDialog_Icon absolute bg-secondary-error rounded-full p-2 shadow-glow-red-button">
                <AppIcon icon-name="disconnect" :width="28" :height="28" color="text-white" />
            </div>
        </header>

        <div class="DisconnectDialog_Message mt-6 text-center">
            <h1>{{ $t('order.disconnect.heading') }}</h1>
            <p class="mt-1 text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('order.disconnect.description') }}</p>
        </div>

        <div class="DisconnectDialog_Actions mt-6 grid row-gap-2">
            <AppButton @click="$emit('close')" size="small" width="full">{{ $t('order.disconnect.stay-connected-button') }}</AppButton>
            <AppButton @click="disconnect" size="small" type="secondary" width="full">{{ $t('order.disconnect.disconnect-button') }}</AppButton>
        </div>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Interface/BaseDialog.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import router from '@/router';

export default {
    name: 'DisconnectDialog',

    components: {
        BaseDialog,
        AppButton,
        AppIcon,
    },

    props: {
        redirect: {
            type: String,
            default: 'HomeScreen',
        },
    },

    setup(props) {
        const { disconnectMachine } = useMachine();
        const { resetOrderState } = useOrder();

        /**
         * Method to disconnect from machine and navigate back to home
         * @returns {void}
         */
        const disconnect = () => {
            disconnectMachine();
            resetOrderState();

            if (props.redirect) {
                router.push({ name: props.redirect });
            }
        };

        return {
            disconnect,
        };
    },
};
</script>

<style lang="scss">
.DisconnectDialog {

    &_Icon {
        bottom: 16px;
        right: 64px;
    }

    &_Actions .ui-AppButton {
        @apply px-3;
    }
}
</style>
