<template>
    <!-- eslint-disable max-len -->
    <header class="layout-TheHomeHeader p-8 flex justify-between items-center dark:bg-night-surface space-x-2">
        <div class="flex-1 flex items-center">
            <button type="button" @click="startOnboarding" aria-label="App Onboarding">
                <AppIcon icon-name="help" :width="24" :height="24" />
            </button>
        </div>

        <RouterLink v-if="machine" :to="{ name: machine.type === 'fridge' ? 'OrderFridge' :  'OrderJunea'}">
            <div class="layout-TheHomeHeader_Machine flex items-center">
                <img :src="require(`@/assets/images/machine-types/${machine.type}.png`)" :alt="machine.name" class="h-12" />

                <div class="layout-TheHomeHeader_MachineInfo ml-2">
                    <h3 class="font-medium leading-none">{{ machine.name }}</h3>

                    <div class="flex items-center text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">
                        <AppIcon icon-name="marker" :width="12" :height="12" />
                        <span class="ml-1 font-normal">{{ machine.location_name }}</span>
                    </div>
                </div>
            </div>
        </RouterLink>

        <div v-else class="flex-1 flex justify-center">
            <SVGLogo height="44" />
        </div>

        <div class="flex-1 flex justify-end items-center">
            <RouterLink v-if="!machine" :to="{ name: 'NewMachine' }">
                <AppIcon icon-name="plus" :width="24" :height="24" />
            </RouterLink>

            <button type="button" v-else @click.prevent="toggleDisconnectDialog">
                <AppIcon icon-name="plus" :width="24" :height="24" />
            </button>
        </div>

        <portal to="dialogs">
            <DisconnectDialog
                v-if="disconnectDialogOpened"
                redirect="NewMachine"
                @close="toggleDisconnectDialog" />
        </portal>
    </header>
</template>

<script>
import DisconnectDialog from '@/components/Dialogs/Disconnect.vue';
import SVGLogo from '@/assets/svg/logo.svg?inline';
import AppIcon from '@/components/Interface/AppIcon.vue';
import router from '@/router';
import useMachine from '@/composables/useMachine';
import { ref } from '@vue/composition-api';

export default {
    name: 'LayoutTheHomeHeader',

    props: {
        currentViewMode: String,
        editModeActivated: Boolean,
    },

    components: {
        DisconnectDialog,
        SVGLogo,
        AppIcon,
    },

    setup() {
        const { machine } = useMachine();

        /**
         * Restart the onboarding proces by clearing
         * onboarding completed item in local storage
         * @returns {void}
         */
        const startOnboarding = () => {
            localStorage.removeItem('onboarding-completed');
            router.push({ name: 'Onboarding' });
        };

        const disconnectDialogOpened = ref(false);

        /**
         * Method to toggle the disconnect dialog
         * @returns {void}
         */
        const toggleDisconnectDialog = () => {
            disconnectDialogOpened.value = !disconnectDialogOpened.value;
        };

        return {
            startOnboarding,
            machine,
            disconnectDialogOpened,
            toggleDisconnectDialog,
        };
    },
};
</script>

<style lang="scss" scoped>
.layout-TheHomeHeader {}
</style>
