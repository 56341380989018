<template>
    <!-- eslint-disable max-len -->
    <div class="HomeScreen flex flex-col min-h-screen bg-day-01dp dark:bg-night-01dp">
        <TheHomeHeader v-if="filteredMachines && filteredMachines.length > 0" />

        <TheDefaultHeader v-else :help="true" />

        <main class="rounded-t-3xl p-5 bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto flex-grow flex flex-col">
            <div class="flex justify-between items-center">
                <button type="button" @click="toggleEditMode" aria-label="Toggle edit mode" class="flex items-center">
                    <AppIcon v-if="editModeActivated" icon-name="exit" key="close-edit" :width="24" :height="24" />
                    <AppIcon v-else icon-name="pencil" key="enter-edit" :width="24" :height="24" />

                    <span v-t="'homescreen.edit'" class="ml-2 text-base"></span>
                </button>

                <button type="button" v-if="currentViewMode === 'slider'" @click="setViewMode('list')" aria-label="Slider view">
                    <AppIcon icon-name="list" key="view-list" :width="24" :height="24" />
                </button>

                <button type="button" v-if="currentViewMode === 'list'" @click="setViewMode('slider')" aria-label="List view">
                    <AppIcon icon-name="slider" key="view-slider" :width="24" :height="24" />
                </button>
            </div>

            <div class="HomeScreen_Machines flex-grow flex justify-center mt-4 overflow-hidden" :class="{
                'HomeScreen_Machines-slider': currentViewMode === 'slider',
                'HomeScreen_Machines-list items-start rounded-3xl': currentViewMode === 'list'
            }">
                <!-- <template v-if="filteredMachines && filteredMachines.length > 0"> -->

                    <!-- Slider view -->
                    <div class="flex flex-1 bg-day-04dp dark:bg-night-04dp rounded-2xl w-full">
                        <keep-alive>
                            <Slider
                                v-if="currentViewMode === 'slider'"
                                ref="machine-slider"
                                :options="machineSliderOptions"
                                :key="`slider-machines-${filteredMachines.length}`">

                                <SliderItem v-for="machine in filteredMachines" :key="`slider-machine-${machine.machine_number}`">
                                    <MachineSliderItem
                                        :saved-machine-data="machine"
                                        :edit-mode-activated="editModeActivated"
                                        @remove-machine="removeMachine($event)"
                                        @hide-machine="hideBrokenMachine($event)"
                                        @connect="connectToMachine($event)" />
                                </SliderItem>

                                <SliderItem key="slider-itemadd-new-machine">
                                    <RouterLink v-if="!connectedMachine" :to="{ name: 'NewMachine' }" class="h-full w-full flex justify-center items-center">
                                        <AppIcon icon-name="plus" class="rounded-full p-2 bg-primary-brand text-white" />
                                        <span class="ml-2 text-base font-medium">{{ $t('homescreen.add-machine') }}</span>
                                    </RouterLink>

                                    <button type="button" v-else @click.prevent="toggleDisconnectDialog" class="h-full w-full flex justify-center items-center">
                                        <AppIcon icon-name="plus" class="rounded-full p-2 bg-primary-brand text-white" />
                                        <span class="ml-2 text-base font-medium">{{ $t('homescreen.add-machine') }}</span>
                                    </button>
                                </SliderItem>
                            </Slider>
                        </keep-alive>
                    </div>

                    <!-- List view -->
                    <div v-if="currentViewMode === 'list'" class="grid gap-4 w-full">
                        <MachineListItem
                            v-for="machine in filteredMachines"
                            :key="`list-machine-${machine.machine_number}`"
                            :saved-machine-data="machine"
                            :edit-mode-activated="editModeActivated"
                            @remove-machine="removeMachine($event)"
                            @hide-machine="hideBrokenMachine($event)"
                            @connect="connectToMachine($event)" />

                        <div class="MachineListItem px-4 py-6 bg-day-04dp dark:bg-night-03dp flex items-center justify-center rounded-3xl dark:shadow-list-item">
                            <RouterLink v-if="!connectedMachine" :to="{ name: 'NewMachine' }" class="flex items-center">
                                <AppIcon icon-name="plus" class="rounded-full p-2 bg-primary-brand text-white" />
                                <span v-t="'homescreen.add-machine'" class="ml-2 text-base font-medium"></span>
                            </RouterLink>

                            <button type="button" v-else @click.prevent="toggleDisconnectDialog" class="flex items-center">
                                <AppIcon icon-name="plus" class="rounded-full p-2 bg-primary-brand text-white" />
                                <span v-t="'homescreen.add-machine'" class="ml-2 text-base font-medium"></span>
                            </button>
                        </div>
                    </div>
                <!-- </template> -->

                <!-- <MachineNoneSaved v-else /> -->
            </div>
        </main>

        <TheBottomMenu />

        <portal to="drawers">
            <MachineConnectDrawer
                v-if="machineConnecting"
                :machine-code="machineConnecting"
                @cancel="cancelConnecting"
                :confirm-before-connect="false" />

            <DisconnectDialog
                v-if="disconnectDialogOpened"
                redirect="NewMachine"
                @close="toggleDisconnectDialog" />
        </portal>
    </div>
</template>

<script>
/* eslint-disable object-curly-newline, max-len, arrow-body-style */
import TheHomeHeader from '@/components/Global/TheHomeHeader.vue';
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import MachineConnectDrawer from '@/components/Drawers/MachineConnect.vue';
import MachineSliderItem from '@/components/Machine/SliderItem.vue';
import MachineListItem from '@/components/Machine/ListItem.vue';
import DisconnectDialog from '@/components/Dialogs/Disconnect.vue';
// import MachineNoneSaved from '@/components/Machine/NoneSaved.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import { slider, slideritem } from 'vue-concise-slider';
import { computed, ref, watch } from '@vue/composition-api';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';

/**
 * Module for switching mode between slider and list
 * @returns {String} currentViewMode
 * @returns {String} setViewMode
 */
const useViewModeSwitch = () => {
    const currentViewMode = ref('slider');

    /**
     * Method to toggle view mode between list and slider mode
     * @returns {void}
     */
    const setViewMode = (viewMode) => {
        currentViewMode.value = viewMode;
    };

    return {
        currentViewMode,
        setViewMode,
    };
};

/**
 * Module for edit mode used to delete machines from LS
 * @returns {String} currentViewMode
 * @returns {String} setViewMode
 */
const useEditMode = () => {
    const editModeActivated = ref(false);

    /**
     * Method to toggle edit enabled
     * @returns {void}
     */
    const toggleEditMode = () => {
        editModeActivated.value = !editModeActivated.value;
    };

    return {
        editModeActivated,
        toggleEditMode,
    };
};

const useSavedMachines = () => {
    const savedMachines = ref(null);
    const hiddenMachines = ref([]);

    // On component created get saved machines from local storage
    savedMachines.value = JSON.parse(localStorage.getItem('saved-machines'));

    /**
     * Method to remove machine from local storage
     *
     * @param {Number} machineNumber
     * @returns {void}
     */
    const removeMachine = (machineNumber) => {
        const machineIndex = savedMachines.value.findIndex((machine) => machine.machine_number === machineNumber);

        if (machineIndex !== -1) {
            savedMachines.value.splice(machineIndex, 1);
        }
    };

    /**
     * Method to remove broken machine (request error other than 200, 201 or 404)
     * from machine array
     */
    const hideBrokenMachine = (machineNumber) => {
        hiddenMachines.value.push(machineNumber);
    };

    /**
     * Filter broken machines out of machine array for slider/list
     */
    const filteredMachines = computed(() => {
        if (!savedMachines.value) return [];
        return savedMachines.value.filter((machine) => !hiddenMachines.value.includes(machine.machine_number));
    });

    /**
     * Watch saved machines array for deleted machine
     * and update local storage if changes
     */
    watch(savedMachines, (machine) => {
        localStorage.setItem('saved-machines', JSON.stringify(machine));
    });

    const machineConnecting = ref(null);

    /**
     * Method enable connecting drawer
     * @returns {void}
     */
    const connectToMachine = (machineNumber) => {
        const { resetOrderState } = useOrder();
        resetOrderState();

        machineConnecting.value = machineNumber;
    };

    /**
     * Method disable connecting drawer
     * @returns {void}
     */
    const cancelConnecting = () => {
        machineConnecting.value = null;
    };

    return {
        savedMachines,
        hiddenMachines,
        removeMachine,
        hideBrokenMachine,
        filteredMachines,
        connectToMachine,
        cancelConnecting,
        machineConnecting,
    };
};

/**
 * Disconnect dialog when adding a new machine but already connected
 */
const useDisconnect = () => {
    const disconnectDialogOpened = ref(false);

    /**
     * Method to toggle the disconnect dialog
     * @returns {void}
     */
    const toggleDisconnectDialog = () => {
        disconnectDialogOpened.value = !disconnectDialogOpened.value;
    };

    return {
        disconnectDialogOpened,
        toggleDisconnectDialog,
    };
};

export default {
    name: 'HomeScreen',

    components: {
        TheHomeHeader,
        TheDefaultHeader,
        TheBottomMenu,
        MachineConnectDrawer,
        MachineSliderItem,
        MachineListItem,
        DisconnectDialog,
        // MachineNoneSaved,
        Slider: slider,
        SliderItem: slideritem,
        AppIcon,
    },

    setup() {
        const { savedMachines } = useSavedMachines();
        const { machine } = useMachine();

        const machineSliderOptions = {
            pagination: savedMachines.value && savedMachines.value.length > 0,
            grabCursor: true,
        };

        return {
            ...useViewModeSwitch(),
            ...useEditMode(),
            ...useSavedMachines(),
            ...useDisconnect(),
            machineSliderOptions,
            connectedMachine: machine,
        };
    },
};
</script>

<style lang="scss">
.HomeScreen {

    &_Machines {
        margin-bottom: 108px; // Dock menu + toolbar

        &-slider {

        }

        &-list {
            overflow: auto;
            max-height: calc(100vh - 260px);
        }

        &:not(:root:root){
            margin-bottom: 21.5vh;
        }

        .slider-container {
            overflow: visible;

            .slider-item {
                font-size: inherit;
                text-align: left;
                color: inherit;
                margin-right: 2rem;
								padding: 16px;
            }

            .slider-pagination {
                bottom: 0.5rem;

                .slider-pagination-bullet {
                    background: theme('colors.day.04dp');
                    height: 0.75rem;
                    width: 0.75rem;
                    opacity: 1;
                    margin: 0 4px;

                    &-active {
                        background: theme('colors.primary.brand');
                    }

                     &:last-child {
                        background-color: transparent;
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3056 5.30556H6.69444V1.69444C6.69444 1.51027 6.62128 1.33363 6.49105 1.2034C6.36081 1.07316 6.18418 1 6 1C5.81582 1 5.63919 1.07316 5.50895 1.2034C5.37872 1.33363 5.30556 1.51027 5.30556 1.69444V5.30556H1.69444C1.51027 5.30556 1.33363 5.37872 1.2034 5.50895C1.07316 5.63919 1 5.81582 1 6C1 6.18418 1.07316 6.36081 1.2034 6.49105C1.33363 6.62128 1.51027 6.69444 1.69444 6.69444H5.30556V10.3056C5.30556 10.4897 5.37872 10.6664 5.50895 10.7966C5.63919 10.9268 5.81582 11 6 11C6.18418 11 6.36081 10.9268 6.49105 10.7966C6.62128 10.6664 6.69444 10.4897 6.69444 10.3056V6.69444H10.3056C10.4897 6.69444 10.6664 6.62128 10.7966 6.49105C10.9268 6.36081 11 6.18418 11 6C11 5.81582 10.9268 5.63919 10.7966 5.50895C10.6664 5.37872 10.4897 5.30556 10.3056 5.30556Z' fill='black' fill-opacity='0.24'/%3E%3C/svg%3E%0A");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100%;
                    }

                    &-active:last-child {
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3056 5.30556H6.69444V1.69444C6.69444 1.51027 6.62128 1.33363 6.49105 1.2034C6.36081 1.07316 6.18418 1 6 1C5.81582 1 5.63919 1.07316 5.50895 1.2034C5.37872 1.33363 5.30556 1.51027 5.30556 1.69444V5.30556H1.69444C1.51027 5.30556 1.33363 5.37872 1.2034 5.50895C1.07316 5.63919 1 5.81582 1 6C1 6.18418 1.07316 6.36081 1.2034 6.49105C1.33363 6.62128 1.51027 6.69444 1.69444 6.69444H5.30556V10.3056C5.30556 10.4897 5.37872 10.6664 5.50895 10.7966C5.63919 10.9268 5.81582 11 6 11C6.18418 11 6.36081 10.9268 6.49105 10.7966C6.62128 10.6664 6.69444 10.4897 6.69444 10.3056V6.69444H10.3056C10.4897 6.69444 10.6664 6.62128 10.7966 6.49105C10.9268 6.36081 11 6.18418 11 6C11 5.81582 10.9268 5.63919 10.7966 5.50895C10.6664 5.37872 10.4897 5.30556 10.3056 5.30556Z' fill='%23ED4A68'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }
}

.mode-dark .HomeScreen {

    .slider-container .slider-pagination .slider-pagination-bullet {
        background-color: theme('colors.night.on-surface');

        &-active {
            background-color: theme('colors.primary.brand');
        }

        &:last-child {
            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3056 5.30556H6.69444V1.69444C6.69444 1.51027 6.62128 1.33363 6.49105 1.2034C6.36081 1.07316 6.18418 1 6 1C5.81582 1 5.63919 1.07316 5.50895 1.2034C5.37872 1.33363 5.30556 1.51027 5.30556 1.69444V5.30556H1.69444C1.51027 5.30556 1.33363 5.37872 1.2034 5.50895C1.07316 5.63919 1 5.81582 1 6C1 6.18418 1.07316 6.36081 1.2034 6.49105C1.33363 6.62128 1.51027 6.69444 1.69444 6.69444H5.30556V10.3056C5.30556 10.4897 5.37872 10.6664 5.50895 10.7966C5.63919 10.9268 5.81582 11 6 11C6.18418 11 6.36081 10.9268 6.49105 10.7966C6.62128 10.6664 6.69444 10.4897 6.69444 10.3056V6.69444H10.3056C10.4897 6.69444 10.6664 6.62128 10.7966 6.49105C10.9268 6.36081 11 6.18418 11 6C11 5.81582 10.9268 5.63919 10.7966 5.50895C10.6664 5.37872 10.4897 5.30556 10.3056 5.30556Z' fill='white' fill-opacity='0.12'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }

        &-active:last-child {
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3056 5.30556H6.69444V1.69444C6.69444 1.51027 6.62128 1.33363 6.49105 1.2034C6.36081 1.07316 6.18418 1 6 1C5.81582 1 5.63919 1.07316 5.50895 1.2034C5.37872 1.33363 5.30556 1.51027 5.30556 1.69444V5.30556H1.69444C1.51027 5.30556 1.33363 5.37872 1.2034 5.50895C1.07316 5.63919 1 5.81582 1 6C1 6.18418 1.07316 6.36081 1.2034 6.49105C1.33363 6.62128 1.51027 6.69444 1.69444 6.69444H5.30556V10.3056C5.30556 10.4897 5.37872 10.6664 5.50895 10.7966C5.63919 10.9268 5.81582 11 6 11C6.18418 11 6.36081 10.9268 6.49105 10.7966C6.62128 10.6664 6.69444 10.4897 6.69444 10.3056V6.69444H10.3056C10.4897 6.69444 10.6664 6.62128 10.7966 6.49105C10.9268 6.36081 11 6.18418 11 6C11 5.81582 10.9268 5.63919 10.7966 5.50895C10.6664 5.37872 10.4897 5.30556 10.3056 5.30556Z' fill='%23ED4A68'/%3E%3C/svg%3E%0A");
        }
    }
}
</style>
