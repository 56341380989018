<template>
    <!-- eslint-disable max-len -->
    <div class="NewMachineModeSwitch bg-day-04dp dark:bg-night-12dp rounded-3xl p-1 grid grid-cols-2 items-center relative z-10" :class="{ 'is-scanning': isScanning }">
        <div @click="$emit('switch', 'scan')" class="py-3 px-4 rounded-3xl cursor-pointer" :class="{
            'bg-primary-brand text-white active-shadow': mode === 'scan',
            'text-gray-300': mode === 'manual',
        }">
            <span>{{ $t('new-machine.toggle.scan-code') }}</span>
        </div>

        <div @click="$emit('switch', 'manual')" class="py-3 px-4 rounded-3xl cursor-pointer" :class="{
            'text-gray-300': mode === 'scan',
            'bg-primary-brand text-white active-shadow': mode === 'manual',
        }">
            <span>{{ $t('new-machine.toggle.enter-code') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mode: String,
        isScanning: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.NewMachineModeSwitch {

    .active-shadow {
        box-shadow: 0px 8px 24px rgba(237, 74, 104, 0.24)
    }

    &.is-scanning {
        @apply bg-night-12dp;
    }
}
</style>
