<template>
    <!-- eslint-disable max-len -->
    <div class="MachineListItem p-2 bg-day-04dp dark:bg-night-16dp flex items-center justify-between rounded-lg dark:shadow-list-item"
        :class="{ 'MachineListItem-offline': !machine.online || !isConnected }">

        <div v-if="!machine.loaded" class="w-full flex items-center justify-between text-day-on-surface-low-emphasis-disabled dark:text-night-06dp">
            <svg width="216" height="56" viewBox="0 0 216 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V48C48 52.4183 44.4183 56 40 56H8C3.58172 56 0 52.4183 0 48V8Z" fill="currentColor"/>
                <path d="M64 18C64 13.5817 67.5817 10 72 10H208C212.418 10 216 13.5817 216 18C216 22.4183 212.418 26 208 26H72C67.5817 26 64 22.4183 64 18Z" fill="currentColor"/>
                <path d="M64 38C64 33.5817 67.5817 30 72 30H168C172.418 30 176 33.5817 176 38C176 42.4183 172.418 46 168 46H72C67.5817 46 64 42.4183 64 38Z" fill="currentColor"/>
            </svg>

            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z" fill="currentColor"/>
            </svg>
        </div>

        <template v-if="machine.loaded">
            <div class="MachineListItem_Left flex items-center">
                <div class="MachineListItem_Image">
                    <img :src="require(`@/assets/images/machine-types/${machine.type}.png`)"
                        :alt="machine.name"
                        class="w-12" />
                </div>

                <div class="MachineListItem_Info mx-2">
                    <div class="flex items-center" :class="machine.online && isConnected ? 'text-white' : 'text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled'">
                        <!-- <AppIcon icon-name="dot" :width="12" :height="12" :class="machine.online ? 'text-primary-brand' : 'dark:divide-night-on-surface-low-emphasis-disabled'" /> -->
                        <h3 class="ml-1 font-medium text-day-on-surface dark:text-white leading-tight">{{ machine.name }}</h3>
                    </div>

                    <div class="flex items-baseline mt-1" :class="machine.online && isConnected ? 'text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis' : 'text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled'">
                        <AppIcon icon-name="marker" :width="12" :height="12" />
                        <span class="ml-1 font-normal">{{ machine.location_name }}</span>
                    </div>
                </div>
            </div>

            <RouterLink v-if="!editModeActivated && machine.online && isConnected && connectedMachine && connectedMachine.machine_number === machine.machine_number" :to="{ name: machine.type === 'fridge' ? 'OrderFridge' :  'OrderJunea' }">
                <AppButton type="primary" size="icon">
                    <AppIcon icon-name="arrow-right" key="connect-machine" :width="24" :height="24" />
                </AppButton>
            </RouterLink>

            <AppButton v-if="!editModeActivated && machine.online && isConnected && (connectedMachine ? connectedMachine.machine_number !== machine.machine_number : true)" @click="$emit('connect', machine.machine_number)" type="primary" size="icon">
                <AppIcon icon-name="arrow-right" key="connect-machine" :width="24" :height="24" />
            </AppButton>

            <AppButton v-if="editModeActivated" @click="$emit('remove-machine', machine.machine_number)" type="danger" size="icon">
                <AppIcon icon-name="trash" key="remove-machine" :width="24" :height="24" />
            </AppButton>

            <AppButton v-if="!machine.online && !editModeActivated || !isConnected && !editModeActivated" type="disabled" size="icon">
                <AppIcon icon-name="cloud-offline" key="offline" :width="24" :height="24" />
            </AppButton>
        </template>
    </div>
</template>

<script>
import AppButton from '@/components/Interface/AppButton.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import useConnection from '@/composables/useConnection';
import { onMounted, ref } from '@vue/composition-api';
import useMachine from '@/composables/useMachine';

export default {
    name: 'MachineListItem',

    props: {
        editModeActivated: Boolean,
        savedMachineData: Object,
    },

    components: {
        AppButton,
        AppIcon,
    },

    setup(props, { root, emit }) {
        const { isConnected } = useConnection();
        const { machine: connectedMachine } = useMachine();

        const machine = ref({
            online: true,
            loaded: false,
        });

        onMounted(async () => {
            try {
                const response = await root.$http.get(`/machine/${props.savedMachineData.machine_number}`);

                const responseMachine = response.data.data;
                machine.value = {
                    machine_number: responseMachine.machine_number,
                    name: responseMachine.name,
                    location_name: responseMachine.location_name,
                    last_used: props.savedMachineData.last_used,
                    online: true,
                    loaded: true,
                    type: props.savedMachineData.type,
                };
            } catch (error) {
                if (error.response.status === 404) {
                    emit('remove-machine', props.savedMachineData.machine_number);
                } else {
                    emit('hide-machine', props.savedMachineData.machine_number);
                }
            }
        });

        return {
            isConnected,
            machine,
            connectedMachine,
        };
    },
};
</script>

<style lang="scss" scoped>
.MachineListItem {

    &-offline {

        .MachineListItem_Image {
            filter: grayscale(1) brightness(0.6);
        }
    }
}
</style>
