<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="closeAndDisconnect" :allow-close="!isConnecting" v-if="machine" class="MachineConnectDrawer">
        <header v-if="isConnecting" class="MachineConnectDrawer_Header text-center flex items-center justify-center">
            <svg class="spinner" viewBox="0 0 100 100" width="20" height="20">
                <circle cx="50" cy="50" r="42" transform="rotate(-90,50,50)" />
            </svg>

            <h1 class="ml-2">{{ $t('homescreen.connecting') }}</h1>
        </header>

        <div class="MachineConnectDrawer_Machine m-4 rounded-3xl py-4 px-2 bg-day-04dp dark:bg-night-06dp">
            <div class="mb-2">
                <img :src="require(`@/assets/images/machine-types/${machine.type}.png`)"
                    :alt="machine.name"
                    class="h-32 mx-auto" />
            </div>

            <div class="flex items-center justify-center">
                <!-- <AppIcon icon-name="dot" :width="14" :height="14" class="text-primary-brand" /> -->
                <h2 class="ml-1 text-day-on-surface-ultra-emphasis dark:text-night-on-surface-ultra-emphasis text-center">{{ machine.name }}</h2>
            </div>

            <div class="flex items-center justify-center mr-4 text-day-text-secondary dark:text-night-text-secondary">
                <AppIcon icon-name="marker" :width="16" :height="16" />
                <span class="ml-1">{{ machine.location_name }}</span>
            </div>
        </div>

        <div v-if="confirmBeforeConnect && !isConnecting" class="MachineConnectDrawer_Confirm mt-4 mx-4">
            <AppButton @click="connectToMachine" width="full">{{ $t('homescreen.button-connect') }}</AppButton>
        </div>
    </BaseDrawer>
</template>

<script>
/* eslint-disable object-curly-newline */
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import { ref } from '@vue/composition-api';
import router from '@/router';
import useMachine from '@/composables/useMachine';

export default {
    name: 'MachineConnectDrawer',

    props: {
        machineCode: {
            type: [String, Number],
            required: true,
        },
        confirmBeforeConnect: Boolean,
    },

    components: {
        BaseDrawer,
        AppIcon,
        AppButton,
    },

    setup(props, { emit, root }) {
        const {
            machine,
            fetchMachineData,
            error,
            disconnectMachine,
            updateMachineLastUsed,
        } = useMachine();

        const isConnecting = ref(false);

        /**
         * Method to call fetch function for machine data
         *
         * @async
         * @returns {void}
         */
        const connectToMachine = async () => {
            isConnecting.value = true;

            // Fetch machine data if not already set
            if (!machine.value || props.machineCode) {
                await fetchMachineData(props.machineCode);
            }

            // If error close connecting drawer
            if (!error.value) {
                setTimeout(() => {
                    if (machine.value.type === 'fridge') {
                        router.push({ name: 'OrderFridge' });
                    } else {
                        router.push({ name: 'OrderJunea' });
                    }

                    isConnecting.value = false;

                    // Show warning toast notification
                    root.$toast.open({
                        message: root.$t('order.connected-notification'),
                        type: 'success',
                        duration: 1200,
                    });

                    updateMachineLastUsed(props.machineCode);
                }, 1400);
            } else {
                emit('cancel');
            }
        };

        if (!props.confirmBeforeConnect) {
            connectToMachine();
        }

        /**
         * Method to cancel the connect drawer and disconnect the machine
         * @returns {void}
         */
        const closeAndDisconnect = () => {
            if (!isConnecting.value) {
                disconnectMachine();
                emit('cancel');
            }
        };

        return {
            machine,
            isConnecting,
            connectToMachine,
            closeAndDisconnect,
        };
    },
};
</script>

<style lang="scss" scoped>
.MachineConnectDrawer {

    &_Header {
        @-webkit-keyframes spin {
            to { stroke-dashoffset: -264; }
        }

        @keyframes spin {
            to { stroke-dashoffset: -264; }
        }

        .spinner circle {
            fill: none;
            stroke: #1a1a1a;
            stroke-width: 16;
            stroke-linecap: round;
            stroke-dasharray: 0, 0, 70, 194;
            stroke-dashoffset: 0;
            animation: spin 1.4s infinite linear;
            -webkit-animation: spin 1.4s infinite linear;
        }
    }
}

.mode-dark .MachineConnectDrawer {
    &_Header {
        .spinner circle {
            stroke: white;
        }
    }

    &_Machine {

        h2, span {
            filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.25));
        }
    }
}
</style>
