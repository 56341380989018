<template>
    <!-- eslint-disable max-len -->
    <div class="MachineSliderItem w-full" :class="{ 'MachineSliderItem-offline': !machine.online || !isConnected }">
        <svg v-if="!machine.loaded" class="w-6/12 h-auto object-contain mx-auto text-day-on-surface-low-emphasis-disabled dark:text-black opacity-25 mb-6" width="152" height="274" viewBox="0 0 152 274" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8C12 3.58173 15.5817 0 20 0H132C136.418 0 140 3.58172 140 8V162C140 166.418 136.418 170 132 170H20C15.5817 170 12 166.418 12 162V8Z" fill="currentColor"/>
            <path d="M0 192C0 187.582 3.58172 184 8 184H144C148.418 184 152 187.582 152 192C152 196.418 148.418 200 144 200H8C3.58172 200 0 196.418 0 192Z" fill="currentColor"/>
            <path d="M20 212C20 207.582 23.5817 204 28 204H124C128.418 204 132 207.582 132 212C132 216.418 128.418 220 124 220H28C23.5817 220 20 216.418 20 212Z" fill="currentColor"/>
            <path d="M14 254C14 242.954 22.9543 234 34 234H118C129.046 234 138 242.954 138 254C138 265.046 129.046 274 118 274H34C22.9543 274 14 265.046 14 254Z" fill="currentColor"/>
        </svg>

        <template v-if="machine.loaded">
            <div class="MachineSliderItem_Image relative">
                <img :src="require(`@/assets/images/machine-types/${machine.type}.png`)"
                    :alt="machine.name"
                    class="MachineSliderItem_Image-product mx-auto" />

                <!-- Background image blur -->
                <!-- <img src="@/assets/images/machine-types/vending.png"
                    :alt="machine.name"
                    class="MachineSliderItem_Image-blur absolute inset-0 w-auto h-full mx-auto rounded-full transform mt-12" /> -->
            </div>

            <div class="MachineSliderItem_Info mt-3 relative">
                <div class="flex items-center justify-center">
                    <!-- <AppIcon icon-name="dot" :width="14" :height="14"
                        :class="machine.online ? 'text-primary-brand' : 'text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled'" /> -->

                    <h2 class="ml-1 whitespace-normal text-center" :class="machine.online && isConnected ? 'text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis' : 'text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled'">
                        {{ machine.name }}
                    </h2>
                </div>

                <div class="flex items-center justify-center flex-wrap text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"
                    :class="machine.online && isConnected ? 'text-white' : 'text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled'">
                    <div v-if="machine.location_name" class="flex items-center mr-4">
                        <AppIcon icon-name="marker" :width="16" :height="16" />
                        <span class="ml-1">{{ machine.location_name }}</span>
                    </div>

                    <div v-if="machine.last_used" class="flex items-center">
                        <AppIcon icon-name="history" :width="16" :height="16" />
                        <span class="ml-1">{{ timeFromNow(machine.last_used) }}</span>
                    </div>
                </div>
            </div>
        </template>

        <div v-if="machine.location_name && machine.loaded" class="MachineSliderItem_Button mt-3 relative">
            <RouterLink :to="{ name: machine.type === 'fridge' ? 'OrderFridge' :  'OrderJunea' }">
                <AppButton
                    v-if="!editModeActivated && machine.online && isConnected && connectedMachine && connectedMachine.machine_number === machine.machine_number"
                    type="primary"
                    icon="arrow-right"
                    key="order-machine"
                    icon-position="right"
                    class="mx-auto">

                    {{ $t('homescreen.button-order') }}
                </AppButton>
            </RouterLink>

            <AppButton
                v-if="!editModeActivated && machine.online && isConnected && (connectedMachine ? connectedMachine.machine_number !== machine.machine_number : true)"
                @click="$emit('connect', machine.machine_number)"
                type="primary"
                icon="arrow-right"
                key="connect-machine"
                icon-position="right"
                class="mx-auto">

                {{ $t('homescreen.button-connect') }}
            </AppButton>

            <AppButton
                v-if="editModeActivated"
                @click="$emit('remove-machine', machine.machine_number)"
                type="danger"
                key="remove-machine"
                icon="trash"
                class="mx-auto">

                {{ $t('homescreen.button-delete') }}
            </AppButton>

            <AppButton
                v-if="!machine.online && !editModeActivated || !isConnected && !editModeActivated "
                type="disabled"
                icon="cloud-offline"
                icon-position="right"
                class="mx-auto">

                {{ $t('homescreen.button-offline') }}
            </AppButton>
        </div>
    </div>
</template>

<script>
import AppButton from '@/components/Interface/AppButton.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import useFilters from '@/filters';
import useConnection from '@/composables/useConnection';
import useMachine from '@/composables/useMachine';
import { onMounted, ref } from '@vue/composition-api';

export default {
    name: 'MachineSliderItem',

    props: {
        editModeActivated: Boolean,
        savedMachineData: Object,
    },

    components: {
        AppButton,
        AppIcon,
    },

    setup(props, { root, emit }) {
        const { timeFromNow } = useFilters();
        const { isConnected } = useConnection();
        const { machine: connectedMachine } = useMachine();

        const machine = ref({
            loaded: false,
            online: true,
        });

        onMounted(async () => {
            try {
                const response = await root.$http.get(`/machine/${props.savedMachineData.machine_number}`);

                const responseMachine = response.data.data;
                machine.value = {
                    machine_number: responseMachine.machine_number,
                    name: responseMachine.name,
                    location_name: responseMachine.location_name,
                    last_used: props.savedMachineData.last_used,
                    online: true,
                    loaded: true,
                    type: props.savedMachineData.type,
                };
            } catch (error) {
                if (error.response.status === 404) {
                    emit('remove-machine', props.savedMachineData.machine_number);
                } else {
                    emit('hide-machine', props.savedMachineData.machine_number);
                }
            }
        });

        return {
            machine,
            isConnected,
            timeFromNow,
            connectedMachine,
        };
    },
};
</script>

<style lang="scss" >
.MachineSliderItem {

    &-offline {

        .MachineSliderItem_Image {
            filter: grayscale(1) brightness(0.6);
        }
    }

    &_Image {

        &-product {
            height: calc(100vh * 0.15);
        }

        &-blur {
            mix-blend-mode: lighten;
            filter: blur(30px);
            opacity: 0.3;
            --transform-scale-x: 1.4;
            --transform-scale-y: 1.4;
            z-index: -1;
        }
    }
}

.mode-dark .MachineSliderItem {

    &_Image {

        &-blur {
            mix-blend-mode: lighten;
            filter: blur(30px);
            --transform-scale-x: 1.4;
            --transform-scale-y: 1.4;
            z-index: -1;
        }
    }
}
</style>
