<template>
    <div class="AppOnboarding relative" @touchstart="handleTouchStart" @touchmove="handleTouchMove">
        <TheOnboardingHeader
            :current-step="currentOnboardingStep"
            :total-steps="onboardingSteps.length" />

        <main class="AppOnboarding_Steps h-full">
            <transition name="onboarding-transition" mode="out-in">
                <OnboardingStep
                    :key="`onboarding-step-${currentOnboardingStep}`"
                    :step-data="onboardingSteps[currentOnboardingStep - 1]"
                    :last-step="currentOnboardingStep === onboardingSteps.length"
                    @next="goToNextSlide" />
             </transition>
        </main>
    </div>
</template>

<script>
/* eslint-disable max-len */
import TheOnboardingHeader from '@/components/Global/TheOnboardingHeader.vue';
import OnboardingStep from '@/components/OnboardingStep.vue';
import { ref, onMounted } from '@vue/composition-api';

export default {
    name: 'AppOnboarding',

    components: {
        TheOnboardingHeader,
        OnboardingStep,
    },

    setup(_, { root }) {
        const onboardingSteps = [
            {
                title: root.$t('onboarding.step-scan.heading'),
                image: 'onboarding-scan.jpg',
                description: root.$t('onboarding.step-scan.description'),
            },
            {
                title: root.$t('onboarding.step-select.heading'),
                image: 'onboarding-select.jpg',
                description: root.$t('onboarding.step-select.description'),
            },
            {
                title: root.$t('onboarding.step-checkout.heading'),
                image: 'onboarding-checkout.jpg',
                description: root.$t('onboarding.step-checkout.description'),
            },
        ];

        const currentOnboardingStep = ref(1);

        let nextSlideInterval = null;

        const goToNextSlide = () => {
            currentOnboardingStep.value = currentOnboardingStep.value !== (onboardingSteps.length)
                ? currentOnboardingStep.value += 1
                : currentOnboardingStep.value = 1;
        };

        onMounted(() => {
            nextSlideInterval = setInterval(() => {
                goToNextSlide();
            }, 6500);
        });

        const xDown = ref(null);
        const yDown = ref(null);

        /**
         * Start touch event handler
         * Get clientX and clientY value of event to determine start point
         *
         * @param {Object} event
         * @returns {void}
         */
        const handleTouchStart = (event) => {
            const firstTouch = event.touches[0];

            xDown.value = firstTouch.clientX;
            yDown.value = firstTouch.clientY;
        };

        /**
         * Touch move event handler
         * Calculate the direction of the swipe event
         *
         * @param {Object} event
         * @returns
         */
        const handleTouchMove = (event) => {
            if (!xDown.value || !yDown.value) {
                return;
            }

            const xUp = event.touches[0].clientX;
            const yUp = event.touches[0].clientY;

            const xDiff = xDown.value - xUp;
            const yDiff = yDown.value - yUp;

            // Swipe to left
            if (Math.abs(xDiff) > Math.abs(yDiff) && xDiff > 10) {
                clearInterval(nextSlideInterval); // Clear the autoplay interval
                goToNextSlide();
            }

            xDown.value = null;
            yDown.value = null;
        };

        return {
            onboardingSteps,
            currentOnboardingStep,
            handleTouchStart,
            handleTouchMove,
            goToNextSlide,
        };
    },
};
</script>

<style lang="scss">
.AppOnboarding {

    .onboarding-transition-enter-active,
    .onboarding-transition-leave-active {
        transition: 0.35s;

        .OnboardingStep_Image,
        .OnboardingStep_Animated-Content {
            transition: 0.35s;
        }
    }

    .onboarding-transition-enter .OnboardingStep_Image,
    .onboarding-transition-leave-to .OnboardingStep_Image {
        opacity: 0;
    }

    .onboarding-transition-enter .OnboardingStep_Animated-Content {
        transform: translateX(100%);
    }

    .onboarding-transition-leave-to .OnboardingStep_Animated-Content {
        transform: translateX(-100%);
    }
}
</style>
