<template>
    <!-- eslint-disable max-len -->
    <header class="layout-TheOnboardingHeader absolute top-0 inset-x-0 z-10 p-8 flex justify-between items-center">
        <div class="layout-TheOnboardingHeader_StepCounter flex items-center flex-1 text-white">
            <span id="current-step" class="text-xl leading-5 font-medium mr-2">{{ currentStep }}</span>
            <span id="total-steps" class="text-sm leading-5 tracking-wider opacity-50">/ {{ totalSteps }}</span>
        </div>

        <div class="layout-TheOnboardingHeader_Logo flex-1 flex justify-center">
            <SVGLogo height="44" class="text-white" />
        </div>

        <button @click="skipOnboarding" class="layout-TheOnboardingHeader_CloseOnboarding flex-1 flex justify-end">
            <AppIcon icon-name="exit" :width="24" :height="24" class="text-white" />
        </button>

        <!-- <div class="layout-TheOnboardingHeader_Dots flex-1 grid grid-flow-col gap-2 justify-end">
            <span
                v-for="dot in totalSteps" :key="`step-dot-${dot}`"
                :class="{ 'bg-primary-brand': dot == currentStep }"
                class="block w-3 h-3 bg-white rounded-full" />
        </div> -->
    </header>
</template>

<script>
import SVGLogo from '@/assets/svg/logo.svg?inline';
import AppIcon from '@/components/Interface/AppIcon.vue';
import router from '@/router';

export default {
    name: 'LayoutTheOnboardingHeader',

    props: {
        currentStep: Number,
        totalSteps: Number,
    },

    components: {
        SVGLogo,
        AppIcon,
    },

    setup() {
        /**
         * Save onboarding completed to local storage
         * @returns {void}
         */
        const onboardingCompleted = () => {
            localStorage.setItem('onboarding-completed', true);
        };

        /**
         * Skip onboarding and go to startpage
         * @returns {void}
         */
        const skipOnboarding = () => {
            onboardingCompleted();
            router.push({ name: 'HomeScreen' });
        };

        return {
            skipOnboarding,
        };
    },
};
</script>

<style lang="scss" scoped>
.layout-TheOnboardingHeader {}
</style>
