<template>
    <!-- eslint-disable max-len -->
    <div class="NewMachine flex flex-col h-full dark:bg-night-01dp" :class="{
        'bg-night-01dp': newMachineMode === 'scan',
        'bg-day-01dp': newMachineMode === 'manual'
    }">
        <TheDefaultHeader :help="true" :close="true" class="z-10" :force-light="newMachineMode === 'scan'" />

        <main class="NewMachine_Content flex-grow flex flex-col items-center justify-center flex-shrink-0 px-4 pb-16">
            <NewMachineScanQR
                v-if="newMachineMode === 'scan'"
                :error="error"
                @code-scanned="connectToMachine($event)" />

            <NewMachineManualEntry
                v-if="newMachineMode === 'manual'"
                :error="error"
                @toggle-dock-visibility="toggleKeyboardActive"
                @code-entered="connectToMachine($event)" />

            <NewMachineModeSwitch
                :mode="newMachineMode"
                @switch="setNewMachineMode($event)"
                :is-scanning="newMachineMode === 'scan'" />
        </main>

        <TheBottomMenu v-if="!keyboardActive" />

        <portal to="drawers">
            <MachineConnectDrawer
                v-if="machineConnecting"
                :machine-code="machineCode"
                @cancel="cancelConnecting"
                :confirm-before-connect="true" />
        </portal>
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import NewMachineScanQR from '@/components/Machine/Register/ScanQR.vue';
import NewMachineManualEntry from '@/components/Machine/Register/ManualEntry.vue';
import NewMachineModeSwitch from '@/components/Machine/Register/ModeSwitch.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import MachineConnectDrawer from '@/components/Drawers/MachineConnect.vue';
import useMachine from '@/composables/useMachine';
import { ref } from '@vue/composition-api';

export default {
    name: 'NewMachine',

    components: {
        TheDefaultHeader,
        NewMachineScanQR,
        NewMachineManualEntry,
        NewMachineModeSwitch,
        TheBottomMenu,
        MachineConnectDrawer,
    },

    setup() {
        const { fetchMachineData, error, machine } = useMachine();

        const newMachineMode = ref('scan');

        /**
         * Method to change the add new machine mode
         *
         * @param {String} mode Scan or manual
         * @returns {void}
         */
        const setNewMachineMode = (mode) => {
            newMachineMode.value = mode;
        };

        const machineConnecting = ref(false);
        const machineCode = ref(null);

        /**
         * Method to call fetch function for machine data
         *
         * @async
         * @returns {void}
         */
        const connectToMachine = async (enteredMachineCode) => {
            machineCode.value = enteredMachineCode;

            // Fetch machine data with machine code
            await fetchMachineData(machineCode.value);

            if (!error.value) {
                machineConnecting.value = true;
            }
        };

        /**
         * Method to cancel connecting to machine
         * @returns {void}
         */
        const cancelConnecting = () => {
            machineConnecting.value = false;
        };

        /**
         * Hide dock when input field is focussed
         */
        const keyboardActive = ref(false);

        const toggleKeyboardActive = () => {
            keyboardActive.value = !keyboardActive.value;
        };

        return {
            newMachineMode,
            setNewMachineMode,
            machineConnecting,
            machineCode,
            connectToMachine,
            cancelConnecting,
            error,
            machine,
            keyboardActive,
            toggleKeyboardActive,
        };
    },
};
</script>

<style lang="scss" scoped>
.NewMachine {

    &_Content {
        margin-bottom: 84px; // Dock menu + toolbar
    }
}
</style>
