<template>
    <!-- eslint-disable max-len -->
    <div class="OnboardingStep flex flex-col">
        <div class="OnboardingStep_Image flex-grow overflow-y-hidden -mb-5 relative">
            <img
                :src="require(`@/assets/images/${stepData.image}`)"
                :alt="stepData.title"
                class="w-full h-full object-cover" />

            <div class="OnboardingStep_Image-overlay absolute inset-0"></div>
        </div>

        <div class="OnboardingStep_Content relative z-0 bg-day-01dp dark:bg-night-01dp p-8 rounded-t-xl overflow-x-hidden flex-shrink-0">
            <div class="OnboardingStep_Animated-Content">
                <h1 class="mb-4">{{ stepData.title }}</h1>
                <p class="dark:text-gray-300">{{ stepData.description }}</p>
            </div>

            <div class="OnboardingStep_Actions mt-8 grid grid-cols-1 gap-2 justify-center">
                <AppButton @click="scanNewMachine" type="primary" icon="scan-code" size="small">{{ $t('onboarding.button-scan') }}</AppButton>

                <AppButton v-if="lastStep" @click="skipOnboarding" type="secondary" icon="arrow-right" icon-position="right" size="small">{{ $t('onboarding.button-skip') }}</AppButton>
                <AppButton v-else @click="$emit('next')" type="secondary" icon="arrow-right" icon-position="right" size="small">{{ $t('onboarding.button-next') }}</AppButton>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from '@/components/Interface/AppButton.vue';
import router from '@/router';

export default {
    name: 'OnboardingStep',

    props: {
        stepData: Object,
        lastStep: Boolean,
    },

    components: {
        AppButton,
    },

    setup() {
        /**
         * Save onboarding completed to local storage
         * @returns {void}
         */
        const onboardingCompleted = () => {
            localStorage.setItem('onboarding-completed', true);
        };

        /**
         * Navigate to add new machine page
         * @returns {void}
         */
        const scanNewMachine = () => {
            onboardingCompleted();
            router.push({ name: 'NewMachine' });
        };

        /**
         * Skip onboarding and go to startpage
         * @returns {void}
         */
        const skipOnboarding = () => {
            onboardingCompleted();
            router.push({ name: 'HomeScreen' });
        };

        return {
            scanNewMachine,
            skipOnboarding,
        };
    },
};
</script>

<style lang="scss" scoped>
.OnboardingStep {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    &_Image {

        &-overlay {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }
    }
}
</style>
