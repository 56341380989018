<template>
    <!-- eslint-disable max-len -->
    <div class="NewMachineManualEntry mx-6">
        <header class="NewMachineManualEntry_Header text-center w-9/12 mx-auto">
            <h1>{{ $t('new-machine.manual-enter.heading') }}</h1>
            <p class="dark:text-gray-300">{{ $t('new-machine.manual-enter.description') }}</p>
        </header>

        <form class="NewMachineManualEntry_Code my-8 grid justify-center grid-flow-col col-gap-3" :class="{
            'NewMachineManualEntry_Code-complete': codeComplete && !error,
            'NewMachineManualEntry_Code-error': codeComplete && error,
        }">
            <input type="number" v-model="code[0]" ref="code-input-1" @input="codeInput($event, 0)" @keyup="codeKeyUp($event, 1)" inputmode="numeric" autocomplete="off" :class="{ 'completed': code[0] }" class="NewMachineManualEntry_CodeInput" @focus="$emit('toggle-dock-visibility')" @blur="$emit('toggle-dock-visibility')"  />
            <input type="number" v-model="code[1]" ref="code-input-2" @input="codeInput($event, 1)" @keyup="codeKeyUp($event, 2)" inputmode="numeric" autocomplete="off" :class="{ 'completed': code[1] }" class="NewMachineManualEntry_CodeInput" @focus="$emit('toggle-dock-visibility')" @blur="$emit('toggle-dock-visibility')" />
            <input type="number" v-model="code[2]" ref="code-input-3" @input="codeInput($event, 2)" @keyup="codeKeyUp($event, 3)" inputmode="numeric" autocomplete="off" :class="{ 'completed': code[2] }" class="NewMachineManualEntry_CodeInput" @focus="$emit('toggle-dock-visibility')" @blur="$emit('toggle-dock-visibility')" />
            <input type="number" v-model="code[3]" ref="code-input-4" @input="codeInput($event, 3)" @keyup="codeKeyUp($event, 4)" inputmode="numeric" autocomplete="off" :class="{ 'completed': code[3] }" class="NewMachineManualEntry_CodeInput" @focus="$emit('toggle-dock-visibility')" @blur="$emit('toggle-dock-visibility')" />
            <input type="number" v-model="code[4]" ref="code-input-5" @input="codeInput($event, 4)" @keyup="codeKeyUp($event, 5)" inputmode="numeric" autocomplete="off" :class="{ 'completed': code[4] }" class="NewMachineManualEntry_CodeInput" @focus="$emit('toggle-dock-visibility')" @blur="$emit('toggle-dock-visibility')" />
            <input type="number" v-model="code[5]" ref="code-input-6" @input="codeInput($event, 5)" @keyup="codeKeyUp($event, 6)" inputmode="numeric" autocomplete="off" :class="{ 'completed': code[5] }" class="NewMachineManualEntry_CodeInput" @focus="$emit('toggle-dock-visibility')" @blur="$emit('toggle-dock-visibility')" />
        </form>
    </div>
</template>

<script>
/* eslint-disable max-len, object-curly-newline */
import { ref, computed, onMounted } from '@vue/composition-api';

export default {
    name: 'NewMachineManualEntry',

    props: ['error'],

    setup(props, { refs, emit }) {
        const code = ref({});

        /**
         * Computed property to determine of the code is compelete
         * @returns {Boolean} Wether the code is complete or not
         */
        const codeComplete = computed(() => {
            const codeLengthValid = Object.keys(code.value).length === 6;
            const hasValidValues = Object.keys(code.value).every((key) => code.value[key]);

            return codeLengthValid && hasValidValues;
        });

        /**
         * Method run after code field input
         * @returns {void}
         */
        const codeInput = (event, inputIndex) => {
            if (event.inputType === 'deleteContentBackward') {
                event.preventDefault();
            } else {
                code.value[inputIndex] = event.data;
            }
        };

        /**
         * Code up event callback on input blocks
         *
         * Make sure you can only enter 1 number in a single input block
         * and auto focus on the next input field or go to previous
         * field is backspace was used.
         *
         * @returns {void}
         */
        const codeKeyUp = (event, inputIndex) => {
            if (event.code === 'Backspace' || event.key === 'Backspace') {
                event.preventDefault();

                if (inputIndex !== 1) {
                    code.value[inputIndex - 2] = '';
                    refs[`code-input-${inputIndex - 1}`].focus();
                }
            } else {
                const inputElement = refs[`code-input-${inputIndex}`];

                if (refs[`code-input-${inputIndex}`].value.length === 1) {
                    if (inputIndex !== 6) {
                        refs[`code-input-${inputIndex + 1}`].focus();
                    } else {
                        inputElement.blur();
                    }
                }

                // Check if code is complete, if so emit code entered event to parent
                if (codeComplete.value) {
                    const formattedCode = Object.values(code.value).join('');
                    emit('code-entered', formattedCode);

                    setTimeout(() => {
                        code.value = {};
                        // refs['code-input-1'].focus();
                    }, 1000);
                }
            }
        };

        // Autofocus first field on component mount
        onMounted(() => {
            refs['code-input-1'].focus();
        });

        return {
            code,
            codeComplete,
            codeInput,
            codeKeyUp,
        };
    },
};
</script>

<style lang="scss">
.NewMachineManualEntry {

    &_Code {
        grid-auto-columns: 2.5rem;

        &-complete {
            filter: drop-shadow(0px 8px 24px rgba(237, 74, 104, 0.2));

            .NewMachineManualEntry_CodeInput {
                box-shadow: 0 0 0 4px rgba(237, 74, 104, 0.2);

                &.completed {
                    filter: drop-shadow(0px 8px 24px rgba(237, 74, 104, 0.1));
                }
            }
        }

        &-error {
            filter: drop-shadow(0px 8px 24px rgba(237, 67, 55, 0.2));

            .NewMachineManualEntry_CodeInput {
                box-shadow: 0 0 0 4px rgba(237, 67, 55, 0.2) !important;

                &.completed {
                    filter: drop-shadow(0px 8px 24px rgba(237, 67, 55, 0.1));
                }
            }
        }
    }

    &_CodeInput {
        background: theme('colors.day.06dp');
        // box-shadow: 0 0 0 2px theme('colors.night.12dp');
        border-radius: theme('borderRadius.lg');
        height: 3.25rem;
        // width: 2.5rem;
        color: theme('colors.black');
        font-family: theme('fontFamily.mono');
        font-weight: 500;
        font-size: theme('fontSize.xl');
        line-height: theme('lineHeight.5');
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        caret-color: transparent;
        cursor: pointer;
        -moz-appearance: textfield;

        &.glow, &:focus {
            box-shadow: 0 0 0 2px theme('colors.day-on-surface.medium-emphasis');
            filter: drop-shadow(0px 8px 24px rgba(237, 74, 104, 0.2));
        }

        &.completed {
            box-shadow: 0 0 0 4px rgba(237, 74, 104, 0.2);
            filter: drop-shadow(0px 8px 24px rgba(237, 74, 104, 0.2));
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.mode-dark .NewMachineManualEntry {

    &_Code {

        &-complete {
            filter: drop-shadow(0px 8px 24px rgba(237, 74, 104, 0.2));

            .NewMachineManualEntry_CodeInput {
                box-shadow: 0 0 0 4px rgba(237, 74, 104, 0.2);

                &.completed {
                    filter: drop-shadow(0px 8px 24px rgba(237, 74, 104, 0.1));
                }
            }
        }
    }

    &_CodeInput {
        background: theme('colors.white');
        box-shadow: 0 0 0 2px theme('colors.night.12dp');
        color: theme('colors.black');

        &.glow, &:focus {
            box-shadow: 0 0 0 4px theme('colors.night.12dp');
            filter: drop-shadow(0px 8px 24px rgba(237, 74, 104, 0.2));
        }

        &.completed {
            box-shadow: 0 0 0 4px rgba(237, 74, 104, 0.2);
            filter: drop-shadow(0px 8px 24px rgba(237, 74, 104, 0.2));
        }
    }
}
</style>
