<template>
    <!-- eslint-disable max-len -->
    <div class="NewMachineScanQR">
        <header class="NewMachineManualEntry_Header text-center w-9/12 mx-auto relative z-10">
            <h1 class="text-white">{{ $t('new-machine.scan.heading') }}</h1>
            <p class="text-gray-300">{{ $t('new-machine.scan.description') }}</p>
        </header>

        <div class="NewMachineScanQR_Frame">
            <QrcodeStream @decode="onDecode" class="absolute inset-0" />

            <svg class="mx-auto relative z-10 my-8 text-white" viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M74.6419 2H18C9.16345 2 2 9.16344 2 18V74.6419" stroke="currentColor" stroke-width="4" stroke-linecap="round"/>
                <path d="M74.6419 258H18C9.16345 258 2 250.837 2 242V185.358" stroke="currentColor" stroke-width="4" stroke-linecap="round"/>
                <path d="M258 74.6419L258 18C258 9.16345 250.837 2 242 2L185.358 2" stroke="currentColor" stroke-width="4" stroke-linecap="round"/>
                <path d="M258 185.358L258 242C258 250.837 250.837 258 242 258L185.358 258" stroke="currentColor" stroke-width="4" stroke-linecap="round"/>
            </svg>
        </div>
    </div>
</template>

<script>
/* eslint-disable max-len  */
// import { ref } from '@vue/composition-api';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    name: 'NewMachineScanQR',

    components: {
        QrcodeStream,
    },

    setup(props, { emit, root }) {
        /**
         * Callback after QR message was decoded
         *
         * @async
         * @param {String} decodedString
         * @return {void}
         */
        const onDecode = async (decodedString) => {
            // Make sure decoded URL is a Binto Quick Response URL
            if (decodedString.includes(process.env.VUE_APP_BINTO_QR_API_URL) || decodedString.includes(process.env.VUE_APP_JUNEA_QR_API_URL)) {
                // Get the hash from the decoded URL
                const qrHash = decodedString.substring(decodedString.lastIndexOf('/') + 1, decodedString.length);

                // Make the request to get the machine code that belongs to the QR hash
                try {
                    const response = await root.$http.get(`${process.env.VUE_APP_BINTO_QR_API_URL}/api/machines/hash/${qrHash}`, {
                        withCredentials: false,
                    });

                    // Emit the code scanned event with the machine number
                    emit('code-scanned', response.data.data.number);
                } catch {
                    try {
                        const response = await root.$http.get(`${process.env.VUE_APP_JUNEA_QR_API_URL}/api/machines/hash/${qrHash}`, {
                            withCredentials: false,
                        });

                        if (response.data?.data?.number) {
                            emit('code-scanned', response.data.data.number);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        };

        // Disable camera in development mode
        const isDevelopmentEnv = process.env.NODE_ENV === 'development';

        return {
            onDecode,
            isDevelopmentEnv,
        };
    },
};
</script>

<style lang="scss">
.NewMachineScanQR {

    &_Frame {

        .qrcode-stream-wrapper {
            @apply absolute inset-0 #{!important};

            .overlay {
                background: linear-gradient(to bottom,
                    rgba(0, 0, 0, 0.8),
                    rgba(0, 0, 0, 0.35),
                    rgba(0, 0, 0, 0.8));
            }
        }

        svg {
            height: calc(100vh * 0.3);

            @media screen and (max-width: 375px) {
                height: calc(100vh * 0.25);
            }

            @media screen and (max-width: 320px) {
                height: calc(100vh * 0.2);
            }
        }
    }
}
</style>
